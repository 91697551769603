.services-list{
    &__wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include breakpoint(medium){
            display: block;
        }
    }
    &__r{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        grid-gap: 20px;
        grid-row-gap: 40px;
        @include breakpoint(small){
            display: block;
        }
        @media screen and (min-width: 768px) and (max-width: 992px) {
            display: grid;
            grid-row-gap: 20px;
        }
        }
    &__elements{
        display: flex;
        align-items: center;
        @include breakpoint(medium){
            margin-bottom: 40px;
        }
        &:hover{
            .title--two{
                color: $blue;
            }
            .services-list__icon{
                filter: invert(.4) sepia(5) saturate(3) hue-rotate(190deg);
            }
        }
        &__icon{
            background: $white;
            box-shadow: -1px 13px 20px 0 rgba(240,242,244,0.70);
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            margin-right: 20px;
        }
    }
    &__imgwrapper{
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding-right: 40px;
        @include breakpoint(medium){
            display: none;
        }
    }
    &__img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__title{
        transition: all ease-in-out 0.4s;
        font-family: $bebas;
        font-size: 20px;
        color: $grey;
        letter-spacing: 0;
        line-height: 24px;
    }
}
.services-list__icon{
    transition: all ease-in-out 0.4s;
    height: 28px;
    width: 28px;
}