.main-navigation {
  &__mobile {
    height: 100%;
    width: 100vw;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    transition: cubic-bezier(0.39, 1.32, 0.46, 0.92) all 0.6s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &--active {
      transform: translate(0);
    }
    &--inactive{
      transform: translate(120vw);
    }
  }
  &__toggle{
    z-index: 11;
  }
}
.menu-toggle {
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  &--white{
    border: 2px solid $white;
  }
  &--black{
    border: 2px solid $black;
  }
  @include breakpoint(large){
    display: none;
  }
  @include breakpoint(xsmall){
    display: block;
  }
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    display: none;
  }
}

.hamburger, .cross {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hamburger span {
  display: block;
  width: 18px;
  height: 2px;
  margin-bottom: 3px;
  overflow: hidden;
  position: relative;
  &:last-child {
    margin: 0;
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-200%);
    transition: transform ease 300ms;
  }
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-200%);
    transition: transform ease 300ms;
    transform: translateX(0);
  }
  &:nth-child(2) {
    &:before, &:after {
      transition-delay: 75ms;
    }
  }
  &:last-child {
    &:before, &:after {
      transition-delay: 150ms;
    }
  }
}
.hamburger{
  &--white{
    span{
      &:before{
        background-color: $white;
      }
      &:after{
        background-color: $white;
      }
    }
  }
  &--black{
    span{
      &:before{
        background-color: $black;
      }
      &:after{
        background-color: $black;
      }
    }
  }
}

.menu-toggle {
  &:hover .hamburger span {
    &:before {
      transform: translateX(0);
    }
    &:after {
      transform: translateX(200%);
    }
  }
  &.active .hamburger span {
    &:before {
      transform: translateX(100%);
    }
    &:after {
      transform: translateX(200%);
    }
  }
}

.cross span {
  display: block;
  width: 18px;
  height: 2px;;
  transform: translateY(50%) rotate(45deg) scaleX(0);
  transition: transform ease 200ms;
  &:last-child {
    transform: translateY(-50%) rotate(-45deg) scaleX(0);
  }
}

.menu-toggle.active .cross span {
  transition-delay: 450ms;
  transform: translateY(50%) rotate(45deg) scaleX(1);
  background-color: $black;
  &:last-child {
    transform: translateY(-50%) rotate(-45deg) scaleX(1);
  }
}
.menu-toggle.active {
  border: 2px solid $black;
  transition-delay: 450ms;
  transition: all ease-in-out 200ms;
  .hamburger span{
    &:before{
      background-color: $black;
    }
    &:after{
      background-color: $black;
    }
  }
}