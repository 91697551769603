.home-projects{
    &__title{
        &:before{
            content: url('/images/shape3.svg');
            position: absolute;
            left: -34%;
            z-index: -1;
        }
    }
    &__description{
        background: $white;
        padding: 20px;
        border-radius: 0 10px 0 0;
        box-shadow: 11px -10px 20px 0 rgba(0,0,0,0.29);
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        min-width: 320px;
        max-width: 340px;
        @include breakpoint(medium){
          width: 100%;
        }
    }
    &__slide{
        height: 60vh;
        background-size: cover;
        background-position: bottom;
        position: relative;
        z-index: 1;
    }
    &__controls{
        display: flex;
        justify-content: center;
        &:before{
            content: url('/images/shape4.svg');
            position: absolute;
            right: 0;
            bottom: -185%;
            z-index: -1;
        }
        &__wrap{
            display: flex;
            margin-top: 20px;
        }
        &__prev{
            width: 60px;
            height: 60px;
            background: $black;
            display: flex;
            border: none;
            align-items: center;
            justify-content: center;
            box-shadow: -3px 6px 14px 0 rgba(0,0,0,0.20);
            margin-right: 10px;
            outline: none;
            cursor: pointer;
        }
        &__next{
            width: 60px;
            height: 60px;
            background: $black;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: -3px 6px 14px 0 rgba(0,0,0,0.20);
            outline: none;
            cursor: pointer;
        }
    }
}
.swiper-container {
    width: 100%;
    height: 50vh;
}
.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }