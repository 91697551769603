.about-page{
    &__wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
        position: relative;
        .home-hero__scroll{
            @include breakpoint(medium){
                display: block;
            }
        }
    }
    &__title{
        color: $white;
        border: 4px solid;
        background: $white;
        mix-blend-mode: multiply;
        font-family: $poppins;
		font-size: 6rem;
		font-weight: 600;
		letter-spacing: -0.5px;
        line-height: 1;
        background-image: url("/images/about-hero.jpg");
        background-size: cover;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: 9;
        position: relative;
        @include breakpoint(small){
            font-size: 4rem;
        }
    }
    &__imgwrap{
        position: absolute;
        right: 0;
        top: 0;
        width: 40%;
        height: 100%;
        overflow: hidden;
        &:before{
            background: rgba(0,0,0,0.50);
            width: 100%;
            height: 100%;
            position: absolute;
            content: '';
            left: 0;
            top: 0;
        }
        @include breakpoint(xsmall){
            display: none;
        }
        @media screen and (min-width: 768px) and (max-width: 992px) {
            height: 60%;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &__image{
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__introwrap{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px;
        @include breakpoint(small){
            display: block;
        }
        .text--paragraph{
            @include breakpoint(small){
                &:last-child{
                    margin-top: 20px;
                }
            }
        }
    }
    &__content{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px;
        align-items: center;
        @include breakpoint(small){
            display: block;
        }
    }
    &__quality{
        height: 90vh;
        overflow: hidden;
        @include breakpoint(small){
            height: 40vh;
            margin-bottom: 20px;
        }
        @media screen and (min-width: 768px) and (max-width: 992px) {
            height: 50vh;
        }
    }
    &__section{
        &--img{
            width: 80%;
            @include breakpoint(medium){
                width: 100%;
            }
        }
    }
    &__partners{
        @include breakpoint(small){
            margin-bottom: 20px;
        }
    }
    &__skill{
        &__number{
            font-family: $bebas;
            font-size: 16px;
            color: $lightgrey;
            letter-spacing: -0.2px;
            position: relative;
            display: block;
            &:after{
                content: '';
                position: absolute;
                top: 45%;
                transform: translateY(-45%);
                left: 20px;
                background: $lightgrey;
                height: 1px;
                width: 50px;
            }
        }
    }
}
.home-hero__scrollanim2{
    margin-bottom: 40px;
    opacity: 1;
    transition: all .5s ease-in 1s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 2px solid $blue;
    border-radius: 50%;
    z-index: 2;
    animation: bounce 2s infinite 2s;
    transform: scale(1);
    &:before {
      transform: rotate(-45deg);
      display: block;
      width: 6px;
      height: 6px;
      content: "";
      border: 2px solid $blue;
      border-width: 0px 0 2px 2px;
    }
  }