.project-single{
    &__head{
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-size: cover;
        background-position: center;
        &:before{
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            background: rgba(0,0,0,0.60);
        }
    }
    &__c{
        position: relative;
    }
    &__r{
        &__wrap{
            display: flex;
            align-items: center;
        }
    }
    &__l{
        width: 70%;
        @include breakpoint(xsmall){
            width: 100%;
            margin-bottom: 40px;
        }
    }
    &__info{
        display: flex;
        justify-content: space-between;
        @include breakpoint(xsmall){
            flex-direction: column;
        }
    }
    &__photo{
      height: 80vh;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      @include breakpoint(xsmall){
        height: auto;
      }
    }
    &__compare{
        position: relative;
        height: 100vh;
        &__wrapper {
            width: 80%;
            height: 80vh;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            overflow: hidden;
          }
          &__before, &__after {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-color: white;
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            overflow: hidden;
          }
          &__img{
            height: 100%;
          }
          &__after {
            width: 125px;
          }
          &__scroller {
            width: 50px;
            height: 50px;
            position: absolute;
            left: 100px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            background-color: $white;
            opacity: 0.9;
            pointer-events: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: -1px 13px 20px 0 rgba(240,242,244,0.20);
            cursor: pointer;
            &:hover {
              opacity: 1;
            }
          }
          &__scroller {
            &:before, &:after {
              content: " ";
              display: block;
              width: 3px;
              height: 100vh;
              position: absolute;
              left: 50%;
              margin-left: -5px;
              z-index: 30;
              transition: 0.1s;
            }
            &:before {
              top: 100%;
            }
            &:after {
              bottom: 100%;
            }
            border: 5px solid $white;
            &:before, &:after {
              background: $white;
            }
          }
    }
}


  .scrolling {
    pointer-events: none;
    opacity: 1;
  }
  
  .scroller__thumb {
    width: 100%;
    height: 100%;
    padding: 5px;
  }