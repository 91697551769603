.projects-list{
    &__wrapper{
        position: relative;
    }
    &__description{
        background: $white;
        padding: 20px;
        border-radius: 0 10px 0 0;
        box-shadow: 11px -10px 20px 0 rgba(0,0,0,0.29);
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        width: 320px;
    }
    &__wrapper{
        height: 60vh;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include breakpoint(medium){
            height: 40vh;
            box-shadow: -1px 13px 20px 0 rgba(240,242,244,0.70);
        }
    }
}