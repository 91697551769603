.mr-20{
    margin-right: 20px;
}
.mb{
    &-200{
        margin-bottom: 200px;
        @include breakpoint(xsmall){
            margin-bottom: 100px;
        }
    }
    &-100{
        margin-bottom: 100px;
        @include breakpoint(small){
            margin-bottom: 50px;
        }
    }
    &-20{
        margin-bottom: 20px;
    }
    &-10{
        margin-bottom: 10px;
    }
}
.mt{
    &-40{
        margin-top: 40px;
    }
    &-20{
        margin-top: 20px;
    }
}
.pt-40{
    padding-top: 40px;
}
.pb-40{
    padding-bottom: 40px;
}
.mb-40{
    margin-bottom: 40px;
    @include breakpoint(xsmall){
        margin-bottom: 20px;
    }
}
.m-auto{
    margin: auto;
  }