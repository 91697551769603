.contact{
    &__wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include breakpoint(xsmall){
            display: block;
        }
        @include breakpoint(medium){
            display: block;
        }
    }
    &__input{
        background: $white;
        box-shadow: 0 3px 8px 0 rgba(0,0,0,0.10);
        border-radius: 5px;
        padding: 15px 0;
        font-family: $poppins;
        font-size: 0.875rem;
        width: 100%;
        margin-bottom: 20px;
        border: 0;
        color: $grey;
        outline: none;
        box-sizing: border-box;
        padding: 15px;
        @include breakpoint(small){
            font-size: 10px;
        }
        &__infos{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;
        }
        &__message{
            height: 150px;
            resize: none;
        }
        &__category{
            height: 55px;
        }
    }
    &__label{
        font-family: $poppins;
        font-size: 0.875rem;
        color: $grey;
        margin-bottom: 20px;
    }
    &__checkbox{
        -webkit-appearance: none;
        width: 18px;
        height: 18px;
        border: 2px solid $blue;
        outline: none;
        cursor: pointer;
        border-radius: 3px;
        position: absolute;
        &--label{
            font-size: 12px;
            color: $grey;
        }
        &:checked{
            background-image: url("/images/checked.svg");
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    &__check{
        position: relative;
        margin: 20px 0;
        label{
            font-family: $poppins;
            font-size: 0.675rem;
            color: $grey;
            text-indent: 10px;
            margin-left: 30px;
            line-height: 2.5;
        }
    }
    &__illustration{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.blurred{
    filter: blur(5px);
}
.freeze{
    overflow: hidden;
}
#devis_date{
    margin-top: 10px;
}