// Load Fonts
@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: url('../fonts/Poppins-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url('../fonts/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'BebasNeue-Regular';
  font-weight: 400;
  src: url('../fonts/BebasNeue-Regular.ttf') format('truetype');
}
$poppins: 'Poppins', sans-serif;
$bebas: 'BebasNeue-Regular', sans-serif;