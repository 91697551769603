.main-navigation{
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  position: relative;
  padding-top: 40px;
  @include breakpoint(medium) {
    padding-top: 20px;
  }
  &__logo{
    margin-right: 20px;
    img{
      @include breakpoint(medium) {
        width: 80%;
      }
    }
  }
  &__l{
    display: flex;
    align-items: center;
  }
  &__r{
    display: flex;
  }
  &__phone{
    display: flex;
    align-items: center;
  }
  &__link{
    margin-right: 20px;
    @include breakpoint(xsmall){
      display: none;
    }
    @include breakpoint(small){
      display: none;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}
.home-hero{
  background-image: url("/images/home-tpc.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  &:before{
    background: rgba(0,0,0,0.70);
    width: 100%;
    height: 80vh;
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    @include breakpoint(xsmall) {
      height: 100vh;
    }
  }
  @include breakpoint(xsmall){
    padding: 0;
  }
  &__c{
    position: relative;
    @include breakpoint(xsmall){
      display: flex;
      justify-content: center;
    }
    &__socials{
      position: absolute;
      top: 50%;
      bottom: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include breakpoint(xsmall){
        display: none;
      }
      img{
        &:last-child{
          margin-top: 20px;
        }
      }
    }
    &__text{
      text-align: center;
    }
  }
  &__title{
    font-family: $bebas;
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
    color: $white;
    @include breakpoint(small) {
      font-size: 2rem;
    }
  }
  &__text{
    font-family: $poppins;
    font-size: 20px;
    letter-spacing: -0.5px;
    line-height: 30px;
    color: $white;
    @include breakpoint(xsmall){
      font-size: 0.875rem;
    }
  }
  &__footer{
    display: grid;
    grid-template-columns: 10% 90%;
    position: relative;
    padding-bottom: 40px;
    @include breakpoint(xsmall){
      display: flex;
      padding-bottom: 0;
    }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      grid-template-columns: auto;
    }
    &__factwrap{
      grid-column: 2;
      display: flex;
      justify-content: space-between;
      @include breakpoint(xsmall){
        flex-direction: column;
      }
      @include breakpoint(small){
        flex-direction: column;
      }
    }
  }
  &__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
    @include breakpoint(xsmall){
      height: 100vh;
      justify-content: space-around;
    }
  }
  &__scroll{
    grid-column: 1;
    display: flex;
    align-items: center;
    @include breakpoint(xsmall){
      display: none;
    }
    @include breakpoint(small){
      align-items: flex-end;
    }
  }
  &__scrollanim{
    opacity: 1;
    transition: all .5s ease-in 1s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 2px solid #FFF;
    border-radius: 50%;
    z-index: 2;
    animation: bounce 2s infinite 2s;
    transform: scale(1);
    &:before {
      transform: rotate(-45deg);
      display: block;
      width: 6px;
      height: 6px;
      content: "";
      border: 2px solid white;
      border-width: 0px 0 2px 2px;
    }
  }
  &__facts{
    display: flex;
    @include breakpoint(xsmall){
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    @include breakpoint(small){
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    &__illu{
      background: $white;
      box-shadow: -3px 6px 14px 0 rgba(0,0,0,0.20);
      padding: 10px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include breakpoint(small){
        width: 60px;
        height: 60px;
      }
    }
    &__text{
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;
    }
  }
}

@keyframes bounce {
  0%, 100%, 20%, 50%, 80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}