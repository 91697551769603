.services-single{
    &__head{
        background-size: cover;
        background-position: center;
        height: 100vh;
        position: relative;
        &:before{
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            background: rgba(0,0,0,0.60);
        }
    }
    &__title{
        height: 200px;
        width: 60vw;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        background: $black;
        border-radius: 0 5px 0 0;
        &__l{
            margin-right: 40px;
        }
        @include breakpoint(small){
            height: 100px;
            width: 90%;
            left: 15px;
        }
        @media screen and (min-width: 768px) and (max-width: 992px) {
            height: 200px;
            width: calc(100% - 30px);
            left: 15px;
        }
    }
    &__titlewrap{
        display: flex;
        transform: translateX(135px);
        align-items: center;
        @include breakpoint(xsmall){
            transform: translateX(15px);
        }
        @media screen and (min-width: 768px) and (max-width: 992px) {
            margin: auto;
            transform: none;
        }
    }
    &__content{
        background: $white;
        z-index: 10;
        position: relative;
    }
    &__subs{
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 40px;
        margin-left: 100px;
        margin-right: 100px;
        @include breakpoint(xsmall){
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        @media screen and (min-width: 768px) and (max-width: 992px) {
            margin-left: 40px;
            margin-right: 40px;
        }
    }
    &__sub{
        background: $blue;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.20);
        padding: 30px;
        border-radius: 5px;
        grid-column: span 6;
    }
    &__li{
        position: relative;
        padding-left: 20px;
        &:before{
            content: '';
            position: absolute;
            height: 110%;
            width: 2px;
            background: $white;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}