.poppin-message{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 11;
  &:before{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background: rgba(0,0,0,0.60);
    width: 100%;
    height: 100vh;
    z-index: 5;
  }
  &:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background: rgba(0,0,0,0.60);
    width: 100%;
    height: 100vh;
    z-index: 4;
    filter: blur(4px);
  }
  &__wrap{
    background: $white;
    box-shadow: -1px 6px 10px 0 rgba(0,0,0,0.30);
    border-radius: 10px;
    padding: 20px;
    position: relative;
    text-align: center;
    z-index: 5;
  }
  // &__cross{
  //     position: absolute;
  //     top: 10px;
  //     right: 10px;
  //     img{
  //         width: 18px;
  //     }
  // }
}

// Category banneer

.category-banneer{
  &:before{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background: rgba(0,0,0,0.60);
    width: 100%;
    height: 100vh;
    z-index: 15;
  }
  &__wrap{
    position: fixed !important;
    z-index: 20;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    box-shadow: -1px 6px 10px 0 rgba(0,0,0,0.30);
    border-radius: 10px;
    padding: 20px;
    position: relative;
    text-align: center;
    z-index: 16;
    width: 30vw;
    @include breakpoint(medium){
      width: 80%;
    }
  }
  &__category{
    display: flex;
    @include breakpoint(medium){
      flex-direction: column;
      .btn--primary{
        margin-bottom: 20px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  &--inactive{
    display: none;
  }
  &--active{
    display: block;
  }
}

// Cookies

.cookies-banneer{
  position: fixed !important;
  bottom: 1em;
  left: 1em;
  background: $white;
  box-shadow: -1px 6px 10px 0 rgba(0,0,0,0.30);
  border-radius: 10px;
  padding: 20px;
  position: relative;
  z-index: 15;
  width: 55vw;
  align-items: center;
  justify-content: space-between;
  &--inactive{
    display: none;
  }
  &--active{
    display: flex;
    @include breakpoint(medium){
      display: block;
      width: 85%;
      left: 50%;
      transform: translateX(-50%);
      .text--paragraph{
        font-size: 10px;
        margin: 0 0 10px 0;
      }
    }
  }
}