@mixin breakpoint($class) {
  @if $class == xsmall {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == small {
    @media (max-width: 768px) { @content; }
  }

  @else if $class == medium {
    @media (max-width: 992px) { @content; }
  }

  @else if $class == large {
    @media (min-width: 1200px) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xsmall, small, medium, large";
  }
}