.home-introduction{
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  &:before{
    content: url('/images/shape1.svg');
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: -1;
  }
  &__txt{
    grid-column: 2;
    text-align: center;
  }
}
.home-services{
  &:before{
    content: url('/images/shape2.svg');
    position: absolute;
    right: 0;
    z-index: -1;
  }
  &__wrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10em;
    @include breakpoint(medium){
      grid-template-columns: 1fr;
      grid-gap: 1em;
    }
  }
  &__card{
    @include breakpoint(medium){
      justify-self: auto;
      margin-bottom: 40px;
      text-align: center;
    }
    &:nth-child(2){
      justify-self: center;
      @include breakpoint(medium){
        justify-self: auto;
        text-align: center;
      }
    }
    &:nth-child(3){
      justify-self: end;
      @include breakpoint(medium){
        justify-self: auto;
        text-align: center;
      }
    }
  }
}