body {
  background: $white;
  overflow-x: hidden;
}

.title{
  &--one{
    font-family: $bebas;
    font-size: 2.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
    @include breakpoint(xsmall){
      font-size: 1.5rem;
    }
    &--white{
      color: $white;
    }
    &--black{
      color: $grey;
    }
    &--blue{
      color: $blue;
    }
  }
  &--two{
    font-family: $poppins;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.5px;
    line-height: 1.5;
    &--black{
      color: $grey;
    }
    &--blue{
      color: $blue;
    }
    &--white{
      color: $white;
    }
  }
  &--three{
    font-family: $bebas;
    font-size: 1.25rem;
    color: $white;
    letter-spacing: 0.3px;
    position: relative;
    padding: 5px;
    z-index: 2;
    display: inline;
    &:before{
      position: absolute;
      content: '';
      background: $blue;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
  &--four{
    font-family: $poppins;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: -0.5px;
    line-height: 1.5;
    &--black{
      color: $grey;
    }
    &--blue{
      color: $blue;
    }
    &--white{
      color: $white;
    }
  }
}
.subtitle{
  &--one {
    font-family: $poppins;
    font-size: 1rem;
    letter-spacing: -0.5px;
    line-height: 24px;
    @include breakpoint(xsmall){
      font-size: 0.875rem;
    }
    &--white{
      color: $white;
    }
    &--black{
      color: $grey;
    }
    &--blue{
      color: $blue;
    }
  }
  &--two{
    font-family: $bebas;
    font-size: 0.875rem;
    color: $lightgrey;
    letter-spacing: 0.3px;
    line-height: 1.5;
    &--white{
      color: $white !important;
    }
  }
  &--three{
    font-family: $bebas;
    font-size: 1.1rem;
    letter-spacing: 0.3px;
    line-height: 1.5;
    &--white{
      color: $white;
    }
    &--black{
      color: $grey;
    }
  }
}
.text{
  &--paragraph{
    font-family: $poppins;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 1.5;
    &--black{
      color: $black;
    }
    &--light{
      color: $lightgrey;
    }
    &--white{
      color: $white;
    }
    &--blue{
      color: $blue;
    }
    &--big{
      font-size: 1rem;
      font-weight: 500;
    }
  }
  &--fill{
    &--blue{
      color: $blue;
    }
    &--white{
      color: $white;
    }
  }
}
.btn{
  &--primary{
    background-image: $gradient1;
    box-shadow: -3px 7px 28px 4px rgba(57,106,252,0.40);
    border-radius: 10px;
    padding: 30px 40px;
    font-family: $bebas;
    font-size: 1.1rem;
    color: $white;
    letter-spacing: 0.3px;
    cursor: pointer;
    display: block;
    width: 130px;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    @include breakpoint(xsmall){
      width: 100px;
      padding: 25px 30px;
      font-size: 0.9rem;
    }
    img{
      padding-left: 10px;
    }
    &:before {
      width: 250px;
      height: 150px;
      border: none;
      background: $white;
      content: "";
      position: absolute;
      top: -250px;
      left: 0;
      -webkit-transform: skewY(15deg) translateY(0);
      transform: skewY(15deg) translateY(0);
      transition: all 0.5s ease-in-out;
    }
    &:after {
      content: attr(data-title);
      position: absolute;
      left: 50%;
      top: 50%;
      transition: all 250ms ease;
      -webkit-transform: translateY(-50%) translateX(-50%);
      transform: translateY(-50%) translateX(-50%);
    }
    &:hover:before {
      top: -50px;
    }
    &:hover:after {
      color: $blue;
    }
  }
  &--secondary{
    font-family: Poppins;
    font-weight: 600;
    font-size: 14px;
    color: $lightgrey;
    letter-spacing: -0.5px;
    position: relative;
    transition: all ease-in-out 0.3s;
    &:hover{
      color: $blue;
      .btn--secondary__icon{
        padding-left: 10px;
      }
    }
    &__icon{
      transition: all ease-in-out 0.3s;
      padding-left: 5px;
    }
  }
  &--border{
    background: none;
    border: 1px solid $blue;
    border-radius: 10px;
    padding: 20px 40px;
    font-family: $bebas;
    font-size: 1.1rem;
    color: $white;
    letter-spacing: 0.3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 130px;
    img{
      padding-left: 10px;
    }
  }
}
.title-section{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  @include breakpoint(xsmall){
    display: block;
  }
  &:after{
    height: 2px;
    width: 30px;
    background: $light;
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    @include breakpoint(xsmall){
      display: none;
    }
  }
  .text--paragraph{
    text-align: justify;
  }
  &__wrap{
    &--l{
      @include breakpoint(xsmall){
        margin-bottom: 20px;
      }
    }
  }
}

.txt-blue{
  color: $blue;
}
.txt-center{
  text-align: center;
}
.txt-justify{
  text-align: justify;
}
a{
  text-decoration: none;
}
.img-fluid{
  max-width: 100%;
  height: auto;
}
.img-fluid2{
  max-width: 90%;
  height: auto;
}
.container-large {
  padding-right: 40px;
  padding-left: 40px;
  @media screen and (min-width: 768px) and (max-width: 992px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include breakpoint(xsmall){
    padding-right: 15px;
    padding-left: 15px;
  }
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
// @media (min-width: 768px) {
//   .container {
//     width: 750px;
//   }
// }
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.d-md-none{
  @include breakpoint(xsmall){
    display: none !important;
  }
  @include breakpoint(medium){
    display: none !important;
  }
}
.hidden{
  display: none;
}