footer{
    background: $black;
}
.footer{
    &__contact{
        padding: 50px 0;
        display: flex;
        justify-content: space-between;
        @include breakpoint(xsmall){
			flex-direction: column;
		}
    }
    &__cta{
        @include breakpoint(xsmall){
			margin: 0 auto 40px auto;
            text-align: center;
            &:last-child{
                margin-bottom: 0;
            }
		}
    }
    &__links{
        padding: 80px 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        @include breakpoint(xsmall){
            grid-template-columns: 1fr 1fr;
        }
    }
    &__c{
        display: flex;
        flex-direction: column;
    }
}